<template>
  <div>
    <Title :text="translations.title_product_list" />
    <div class="viewcontent">
      <div
        v-if="Object.keys(products.exhibitor).length !== 0"
        class="baner"
        @click="linkTo(products.exhibitor.code, 'exhibitor')"
      >
        <img :src="files + products.exhibitor.picture" alt="" />
        <div class="info fourth_bg_color_transparent contrast_color">
          <h2>{{ products.exhibitor.full_name }}</h2>
          <p v-if="products.list.products[0].category">
            {{ products.list.products[0].category }}
          </p>
          <p v-else>
            {{ products.exhibitor.description }}
          </p>
        </div>
      </div>
      <SearchList :data="products" :type="'product'" />
      <button @click="getAllProducts()" v-if="filter.keyword !== '*'">
        {{ translations.btn_showall_exhibitor_products }}
      </button>
    </div>
  </div>
</template>

<script>
  import Title from '@/components/Title.vue'
  import SearchList from '@/components/SearchList.vue'
  import router from '../router'

  import { mapActions, mapState } from 'vuex'
  export default {
    name: 'ProductList',
    components: {
      Title,
      SearchList,
    },
    data() {
      return {
        filter: {},
      }
    },
    methods: {
      ...mapActions(['getProducts', 'resetProductList']),
      linkTo(id, type) {
        switch (type) {
          case 'exhibitor':
            router.push('/exhibitor/' + id)
            break
        }
      },
      async getAllProducts() {
        this.filter = { keyword: '*' }
        await this.getProducts(this.filter)
      },
    },
    computed: {
      ...mapState(['products', 'files', 'translations']),
    },
    async created() {
      if (!this.products.list.products) {
        this.filter = JSON.parse(localStorage.getItem('product'))
        await this.getProducts(this.filter)
      }
    },
    beforeUnmount() {
      this.resetProductList()
    },
  }
</script>

<style scoped>
  .baner {
    height: 300px;
    overflow: hidden;
    position: relative;
    margin-bottom: 30px;
  }
  .baner img {
    /* width: 100%; */
    position: absolute;
    left: -100%;
    right: -100%;
    top: -100%;
    bottom: -100%;
    margin: auto;
    min-height: 100%;
    min-width: 100%;
  }
  .info {
    position: absolute;
    bottom: 0;
    right: 0;
    top: 50%;
    left: 0;
    padding: 20px;
    text-align: center;
  }
  .info h2 {
    text-transform: uppercase;
  }
</style>
